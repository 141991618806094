<template>
  <div class="vx-row">
    <div class="vx-col w-full">
      <vx-card >
        <div class="vx-row">
          <div class="vx-col w-full">
            <div class="flex items-end px-3">
              <feather-icon svgClasses="w-6 h-6" icon="AlignJustifyIcon" class="mr-2" />
              <span class="font-medium text-lg leading-none">Billetages</span>
            </div>
            <vs-divider />
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <vs-table v-model="selected" pagination max-items="10" search data="">
              <template slot="header">
                <!-- ACTION - DROPDOWN -->
                <vs-dropdown vs-trigger-click class="cursor-pointer">

                  <div class="p-3 shadow-drop rounded-lg d-theme-dark-light-bg cursor-pointer flex items-end justify-center text-lg font-medium w-32">
                    <span class="mr-2 leading-none">Actions</span>
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                  </div>

                  <vs-dropdown-menu>

                    <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>Supprimer</span>
                      </span>
                    </vs-dropdown-item>
                    <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>Print</span>
                      </span>
                    </vs-dropdown-item>

                    <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="SaveIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>CSV</span>
                      </span>
                    </vs-dropdown-item>

                  </vs-dropdown-menu>
                </vs-dropdown>
                <vs-button class="mb-4 ml-5 md:mb-0" @click="$router.push('/apps/billetage/billetage-add')">Nouveau billetage</vs-button>
              </template>
              <template slot="thead">
                <vs-th sort-key="numero">
                  N°
                </vs-th>
                <vs-th sort-key="IdOfficine">
                  Officine
                </vs-th>
                <vs-th sort-key="IdUser">
                  User
                </vs-th>
                <vs-th sort-key="DateCloture">
                  Date cloture
                </vs-th>
                <vs-th sort-key="MontDixMil">
                  Nbr 10.000
                </vs-th>
                <vs-th sort-key="MontCqMil">
                  Nbr 5.000
                </vs-th>
                <vs-th sort-key="MontDeuxMil">
                  Nbr 2.000
                </vs-th>
                <vs-th sort-key="MontMil">
                  Nbr 1.000
                </vs-th>
                <vs-th sort-key="MontCqCnt">
                  Nbr 500
                </vs-th>
                <vs-th sort-key="MontDeuxCnt">
                  Nbr 200
                </vs-th>
                <vs-th sort-key="MontCnt">
                  Nbr 100
                </vs-th>
                <vs-th sort-key="TotalEncaise">
                  En Caisse
                </vs-th>

              </template>

              <template slot-scope="{data}">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" >
                  <vs-td :data="data[indextr]">
                    {{indextr + 1}}
                  </vs-td>

                  <vs-td :data="data[indextr].IdOfficine">
                    {{data[indextr].IdOfficine}}
                  </vs-td>
                  <vs-td :data="data[indextr].IdUser">
                    {{data[indextr].IdUser}}
                  </vs-td>
                  <vs-td :data="data[indextr].DateCloture">
                    {{data[indextr].DateCloture}}
                  </vs-td>
                  <vs-td :data="data[indextr].MontDixMil">
                    {{data[indextr].MontDixMil}}
                  </vs-td>

                  <vs-td :data="data[indextr].MontCqMil">
                    {{data[indextr].MontCqMil}}
                  </vs-td>
                  <vs-td :data="data[indextr].MontDeuxMil">
                    {{data[indextr].MontDeuxMil}}
                  </vs-td>

                  <vs-td :data="data[indextr].MontMil">
                    {{data[indextr].MontMil}}
                  </vs-td>
                  <vs-td :data="data[indextr].MontCqCnt">
                    {{data[indextr].MontCqCnt}}
                  </vs-td>

                  <vs-td :data="data[indextr].MontDeuxCnt">
                    {{data[indextr].MontDeuxCnt}}
                  </vs-td>
                  <vs-td :data="data[indextr].MontCnt">
                    {{data[indextr].MontCnt}}
                  </vs-td>

                  <vs-td :data="data[indextr].TotalEncaise">
                    {{data[indextr].TotalEncaise}}
                  </vs-td>

                  <vs-td :data="data[indextr]._id">
                    <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
                      <feather-icon icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="updateBilletageShowForm(data[indextr])" />
                      <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDeleteRecord(data[indextr])" />
                    </div>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      selected:[],
      billetages:[],
      billetageId: ''
    }
  },
  computed: {
    validateForm () {
      return !this.errors.any() && this.libelle !== ''
    }
  },

  methods: {
    updateBilletageShowForm (data) {
      this.$router.push(`/apps/billetage/billetage-edit/${data._id}`).catch((err) => { console.log(err) })
    },
    confirmDeleteRecord (data) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm Delete',
        text: `You are about to delete "${data.TotalEncaise}"`,
        accept: this.deleteRecord,
        acceptText: 'Delete'
      })
    },
    deleteRecord () {
      // alert('supprimé !')
    //   this.$store.dispatch('moduleBilletage/removeBilletage', this.params.data._id)
      // .then((res) => {
      //   console.log('success >> ', res)
      //   this.showDeleteSuccess()
      // })
      // .catch(err => { console.error(err)       })
      this.showDeleteSuccess()
    },
    showDeleteSuccess () {
      this.$vs.notify({
        color: 'success',
        title: 'User Deleted',
        text: 'The selected user was successfully deleted'
      })
    }
  },
  created () {
  }
}
</script>

